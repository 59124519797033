// © frwrd Corporation. All rights reserved.

import { SpotPriceResponse } from "../Models/Models";

//
// https://www.energidataservice.dk/tso-electricity/elspotprices
// 
export const SpotPriceClient = {
  Config: {
    // limit calculation:
    // 1 reading hours = 24 readings pr price area
    // 2 price areas: DK1 + DK2
    // = 24 reading pr day * 2 price areas = 48 reading pr day
    // 1 day ahead reading + current day + yesterday + 1:
    // limit = 48 * 4 = 192
    //baseUrl: 'https://api.energidataservice.dk/datastore_search_sql?sql=SELECT "HourUTC", "HourDK", "PriceArea", "SpotPriceDKK", "SpotPriceEUR" FROM "elspotprices" WHERE "PriceArea" = \'DK1\' OR "PriceArea" = \'DK2\' ORDER BY "HourDK" DESC LIMIT 192',
    //    baseUrl: 'https://api.energidataservice.dk/dataset/Elspotprices?offset=0&filter={"PriceArea":"DK2,DK1"}&sort=HourUTC DESC&timezone=utc&limit=144',
    baseUrl: 'https://api.energidataservice.dk/dataset/Elspotprices?offset=0&filter={"PriceArea":"DK2"}&sort=HourUTC DESC&timezone=utc&limit=72',

    errorMessage: 'Invalid response ',
  },
  getSpotPrices: async (): Promise<SpotPriceResponse> => {
    // this is the public api endpoint with no authentication requirements!
    const response = await fetch(`${SpotPriceClient.Config.baseUrl}`);
    if (response.ok) {
      return response.json();
    }
    throw new Error(SpotPriceClient.Config.errorMessage);
  },
};