import { Bar } from "react-chartjs-2";
import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    Tooltip,
    LineElement,
    PointElement,
    LineController,
    //registerables
} from 'chart.js';

Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    Tooltip,
    LineElement,
    LineController,
    PointElement,
    //...registerables
);

export interface ChartProps {
    data: any;
}

function MyChart(props: ChartProps) {
    const options: any = {
        responsive: true,
        aspectRatio: 1,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: "Rå el pris (spot) i DK2 - u. moms & afgifter"
            },
            legend: {
                display: false,
                position: "top"
            }
        }
    };

    return (
        <Bar datasetIdKey="1"
            data={props.data}
            options={options}
        />
    );
};

export default MyChart;