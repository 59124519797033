// import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
// import { InteractionStatus } from '@azure/msal-browser';
// import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import './App.css';
import MyChart from './components/chart';
//import { msalAuthenticationRequest } from './constants';
//import { ApiClient } from './Utils/ApiClient';
import { SpotPriceClient } from './Utils/SpotPriceClient';

function App() {
  const [chartData, setChartData] = useState<any>();
  const [failed, setFailed] = useState<boolean>(false);


  useEffect(() => {

    // https://stackoverflow.com/questions/15852122/hex-transparency-in-colors
    const todayColor = '#36A2EB33';
    const thisHourColor = '#36A2EB';
    const yesterdayColor = '#D4D4D4B3'
    const tomorrowColor = '#FF63844D'

    SpotPriceClient.getSpotPrices()
      .then((res) => {

        const now = new Date(Date.now());
        const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 0, 0, 0);
        const yesterday = new Date(new Date(new Date().setDate(now.getDate() - 1)).setHours(0));

        const map = res.records.map(d => ({ HourDK: new Date(d.HourDK), PriceArea: d.PriceArea, SpotPriceDKK: d.SpotPriceDKK / 1000.0, SpotPriceEUR: d.SpotPriceEUR / 1000.0 }))
        const relevantData = map.filter(d => d.HourDK >= yesterday);
        const ordered = relevantData.sort((a, b) => a.HourDK.getTime() - b.HourDK.getTime());
        const filter = ordered.filter(d => d.PriceArea === 'DK2');
        const lastElementWithPrice = filter
          .filter(d => d.SpotPriceDKK !== 0 && d.SpotPriceEUR !== 0)
          .slice(-1)[0];

        const eurToDkkConversionRate = lastElementWithPrice
          ? lastElementWithPrice.SpotPriceDKK / lastElementWithPrice.SpotPriceEUR
          : 7.5;


        const todaysData = filter.filter(l => new Date(l.HourDK) >= todayStart && new Date(l.HourDK) <= todayEnd);
        const todaysPrices = todaysData.map(l => l.SpotPriceDKK > 0 ? l.SpotPriceDKK : l.SpotPriceEUR * eurToDkkConversionRate);
        const todaysAveragePrice = todaysPrices.reduce((partialSum, a) => partialSum + a, 0) / todaysPrices.length;

        const yesterdayAveragePrice = filter
          .filter(l => new Date(l.HourDK) < todayStart)
          .map(l => l.SpotPriceDKK > 0 ? l.SpotPriceDKK : l.SpotPriceEUR * eurToDkkConversionRate)
          .reduce((partialSum, a) => partialSum + a, 0) / filter.filter(l => new Date(l.HourDK) < todayStart).length;

        const tomorrowAveragePrice = filter
          .filter(l => new Date(l.HourDK) > todayEnd)
          .map(l => l.SpotPriceDKK > 0 ? l.SpotPriceDKK : l.SpotPriceEUR * eurToDkkConversionRate)
          .reduce((partialSum, a) => partialSum + a, 0) / filter.filter(l => new Date(l.HourDK) > todayEnd).length;

        // https://stackoverflow.com/questions/3552461/how-do-i-format-a-date-in-javascript
        const options: Intl.DateTimeFormatOptions = { weekday: 'narrow', /*year: '2-digit',*/ month: 'short', day: '2-digit' };

        setChartData({
          labels: filter.map(l => l.HourDK.toLocaleDateString("da-DK", options) + ` Kl: ${l.HourDK.getHours()}`),
          datasets: [
            {
              type: 'line' as const,
              label: 'gen. snit',
              borderColor: 'rgb(255, 99, 132)',
              borderWidth: 1,
              fill: false,
              data: filter.map((l) => {
                if (new Date(l.HourDK) < todayStart) return yesterdayAveragePrice;
                else if (new Date(l.HourDK) > todayEnd) return tomorrowAveragePrice;
                else if (new Date(l.HourDK) >= todayStart && new Date(l.HourDK) <= todayEnd) return todaysAveragePrice;
                else return -1;
              }),
            },
            {
              type: 'bar' as const,
              label: 'Pris DKK',
              data: filter.map(l => l.SpotPriceDKK > 0 ? l.SpotPriceDKK : l.SpotPriceEUR * eurToDkkConversionRate),

              backgroundColor: filter.map(l => {
                const local = l.HourDK;
                if (local.getDate() === now.getDate() && local.getHours() === now.getHours()) {
                  return thisHourColor;
                }
                else if (local.getDate() === now.getDate()) {
                  return todayColor;
                }
                else if (local.getDate() >= now.getDate()) {
                  return tomorrowColor;
                }
                else {
                  return yesterdayColor;
                }
              }),

              borderColor: filter.map(l => {
                const local = l.HourDK;
                if (local.getDate() === now.getDate() && local.getHours() === now.getHours()) {
                  return thisHourColor;
                }
                else if (local.getDate() === now.getDate()) {
                  return todayColor;
                }
                else if (local.getDate() >= now.getDate()) {
                  return tomorrowColor;
                }
                else {
                  return yesterdayColor;
                }
              }),
              borderWidth: 1
            }]
        });
      }).catch(err => {
        setFailed(true);
      });
  }, []);

  return (
    <div className="App">
      {!chartData && failed && <div><p>Sorry, please try loading the page again...</p></div>}
      {chartData && <MyChart data={chartData} />}
      {!chartData && (
        <div>
          <ScaleLoader color="#36A2EB" />
        </div>
      )}
    </div>
  );
}

export default App;